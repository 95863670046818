/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http'
import { BehaviorSubject, from, Observable, throwError } from 'rxjs'
import { CustomHttpParams, IApiConfig } from '@sightline/models-misc'
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators'
import { API_CONFIG } from '../users/userApi.service'
import { AuthWrapperService } from './auth-wrapper.service'
import { PreviewModeSessionKeys } from '../../../../src/commons/services/business/cache.service'

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authWrapper: AuthWrapperService, @Inject(API_CONFIG) @Optional() private config?: IApiConfig) { }

  isRefreshingToken = false
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    if (this.config && request.url.indexOf(this.config.url) < 0) {
      return next.handle(request)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return next.handle(this.addTokenToRequest(request)).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) {
          return this.handle401Error(request, next)
        } else {
          return throwError(err)
        }
      })
    )
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    //preview mode shouldn't handle 401 errors
    sessionStorage.removeItem(PreviewModeSessionKeys.SLP_PREVIEW_MODE)
    sessionStorage.removeItem(PreviewModeSessionKeys.SLP_PREVIEW_MODE_TYPE)
    sessionStorage.removeItem(PreviewModeSessionKeys.SLP_PREVIEW_TOKEN)
    //
    
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null)
      return from(this.authWrapper.refreshToken()).pipe(
        switchMap(() => {
          this.isRefreshingToken = false
          return next.handle(this.addTokenToRequest(request))
        }),
        catchError(() => {
          return <any>this.authWrapper.logOut()
        }),
        finalize(() => {
          this.isRefreshingToken = false
        })
      )
    } else {
      this.isRefreshingToken = false
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.addTokenToRequest(request))
        })
      )
    }
  }

  public addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const customParams = request.params as CustomHttpParams
    if (!customParams?.custom?.noAuthorization) {
      const token: string = this.authWrapper.getAccessToken()

      if (token) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
      }
    }
    return request
  }
}
