/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ForgotPasswordValidators } from '../validators/forgot-password.validators'
import { ActivatedRoute, Router } from '@angular/router'
import { PlatformService } from '../services/business/platform.service'
import { CacheService } from '../services/business/cache.service'
import { NotificationService } from '../services/ui/notification.service'
import { PasswordRule, PasswordRuleEvaluator, PasswordService } from '../../../src/security/services/passwordRules'
import { TokenType } from '../../../src/security/models'
import { UserApiService } from '../../../src/security/services/users/userApi.service'
import { Utils } from '../utils/utils'
import { GoogleAnalyticsEvents } from '../enums/google-analytics-events.enum'

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  @ViewChild('successMessage', { read: ElementRef }) successMessage: ElementRef

  private _successColor = 'success'
  private _errorColor = 'danger'
  private _warningIcon = 'warning'
  private _checkmarkIcon = 'checkmark-circle'
  public forgotPasswordForm: FormGroup
  public platformClass: string
  public showPassword = false
  public showConfirmPassword = false
  public showError = false
  public isTokenValid = false
  public _isResetSuccessful = false
  public errorText
  public siteUrl = document.baseURI
  public validateAgainstToken = true;

  public get googleAnalyticsEvents(): typeof GoogleAnalyticsEvents {
    return GoogleAnalyticsEvents
  }

  public inputType(showPassword: boolean): string {
    return showPassword ? 'text' : 'password'
  }

  public passwordIconTitle(showPassword: boolean): string {
    return this.translate.instant(
      showPassword
        ? 'commons.changePassword.hidePassword'
        : 'commons.changePassword.showPassword'
    ) as string
  }

  public passwordIconName(showPassword: boolean): string {
    return showPassword ? 'eye-off-outline' : 'eye-outline'
  }

  public get showPasswordValidations(): boolean {
    return (
      this.forgotPasswordForm.controls['password'].dirty ||
      this.forgotPasswordForm.controls['password'].touched
    )
  }

  public get showPasswordConfirmValidations(): boolean {
    return (
      this.forgotPasswordForm.controls['confirmPassword'].dirty ||
      this.forgotPasswordForm.controls['confirmPassword'].touched
    )
  }

  public get passwordConfirmMatchColor(): string {
    return this.forgotPasswordForm.controls['confirmPassword'].hasError(
      'required'
    ) ||
      this.forgotPasswordForm.controls['confirmPassword'].hasError(
        'NoPassswordMatch'
      )
      ? this._errorColor
      : this._successColor
  }

  public get passwordConfirmMatchIcon(): string {
    return this.forgotPasswordForm.controls['confirmPassword'].hasError(
      'required'
    ) ||
      this.forgotPasswordForm.controls['confirmPassword'].hasError(
        'NoPassswordMatch'
      )
      ? this._warningIcon
      : this._checkmarkIcon
  }

  public get isPasswordValid(): boolean {
    return this.validatorMessages.filter(m => m.color == this._errorColor).length == 0
  }

  public get validatorMessages(): {
    rule: string
    color: string
    icon: string
  }[] {
    const passwordErrors = this.forgotPasswordForm.get('password').errors
    return this.paswordRules.map((passwordRule) => {
      return {
        rule: this.translate.instant(
          `commons.passwordRules.${passwordRule.id}`
        ),
        color:
          passwordErrors &&
            (passwordErrors['required'] || passwordErrors[passwordRule.id])
            ? this._errorColor
            : this._successColor,
        icon:
          passwordErrors &&
            (passwordErrors['required'] || passwordErrors[passwordRule.id])
            ? this._warningIcon
            : this._checkmarkIcon,
      }
    })
  }

  public get isFormVisible(): boolean {
    return this.isTokenValid && (!this._isResetSuccessful || !this.validateAgainstToken)
  }

  public paswordRules: PasswordRule[] = []

  constructor(
    private readonly translate: TranslateService,
    public platform: PlatformService,
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private userApiService: UserApiService,
    private route: ActivatedRoute,
    private router: Router,
    private cacheService: CacheService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.setPasswordRules()
    this.forgotPasswordForm = this.createSignupForm()
  }

  async ngOnInit(): Promise<void> {
    await this.validateToken()
  }

  public async validateToken(): Promise<void> {
    try {
      const forgotPasswordToken = this.route.snapshot.paramMap.get('token')
      if (forgotPasswordToken) {
        ForgotPasswordValidators.userToken = forgotPasswordToken
        const userFromToken = await this.userApiService.validateToken(
          forgotPasswordToken,
          TokenType.Forgotpassword,
          this.cacheService.getPreviewMode()
        )
        this.isTokenValid = true
        if (this.isTokenValid) {
          this.forgotPasswordForm.get('firstName').setValue(userFromToken.firstName)
          this.forgotPasswordForm.get('lastName').setValue(userFromToken.lastName)
          this.forgotPasswordForm.get('userId').setValue(Utils.removeUserPrefix(userFromToken.email))
        }
      }
      else {
        this.validateAgainstToken = false
        const account = this.cacheService.getSelectedAccount()
        const user = await this.cacheService.getUserInfo()

        if (account) {
          this.isTokenValid = true
          this.forgotPasswordForm.get('firstName').setValue(account.firstName)
          this.forgotPasswordForm.get('lastName').setValue(account.lastName)
          this.forgotPasswordForm.get('userId').setValue(Utils.removeUserPrefix(user.username))
        }
      }
    }
    catch (err) {
      this.isTokenValid = false
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      if (err?.status === 400) {
        this.errorText = 'forgotPasswordError'
      } else {
        this.errorText = 'InternalError'
      }
    }
  }

  backToLogin(): void {
    void this.router.navigate([''])
  }

  private showSuccessMessage() {
    this.errorText = ''
    void this.notificationService.showToasterMessage({
      message: this.translateService.instant('commons.changePassword.successMessage') as string,
    })
    this.forgotPasswordForm.reset()
  }

  public onSubmit(): void {
    if (this.validateAgainstToken) {
      this.userApiService
        .resetPassword(
          this.route.snapshot.paramMap.get('token'),
          this.forgotPasswordForm.get('password').value,
          this.cacheService.getPreviewMode()
        )
        .then((response) => {
          this._isResetSuccessful = response
        })
        .catch(() => {
          this.errorText = 'passwordError'
        })
    }
    else {
      this.userApiService
        .changePassword(
          this.forgotPasswordForm.get('password').value
        )
        .then((response) => {
          this._isResetSuccessful = response
          if (response) {
            this.showSuccessMessage()
          }
          else {
            this.errorText = 'passwordError'
          }
        })
        .catch((err) => {
          console.log(err)
          this.errorText = 'passwordError'
        })
    }
  }

  public enterSubmit(event: KeyboardEvent): void {
    const target: HTMLElement = event.target as HTMLElement

    if (
      target &&
      target.nodeName === 'INPUT' &&
      this.forgotPasswordForm.valid &&
      (event.code === 'Enter' || event.code === 'NumpadEnter')
    ) {
      this.onSubmit()
    }
  }

  public showHidePassword(): void {
    this.showPassword = !this.showPassword
  }

  public showHideConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword
  }

  private setPasswordRules(): void {
    ForgotPasswordValidators.userApiService = this.userApiService
    ForgotPasswordValidators.passwordRuleEvaluator = new PasswordRuleEvaluator(
      ForgotPasswordValidators.updateRules
    )
    this.passwordService
      .getPasswordRules()
      .then((passwordRules) => {
        this.paswordRules = passwordRules
        ForgotPasswordValidators.passwordRuleEvaluator.addRules(passwordRules)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  private createSignupForm(): FormGroup {
    return this.fb.group(
      {
        userId: new FormControl('') as AbstractControlOptions,

        password: new FormControl('', [
          Validators.required,
        ]) as AbstractControlOptions,

        confirmPassword: new FormControl('', [
          Validators.required,
        ]) as AbstractControlOptions,

        firstName: new FormControl('') as AbstractControlOptions,

        lastName: new FormControl('') as AbstractControlOptions,

        token: new FormControl('') as AbstractControlOptions,
      },

      {
        validator: [
          ForgotPasswordValidators.passwordMatchValidator,
          ForgotPasswordValidators.passwordFieldValidator,
        ],
      } as AbstractControlOptions
    )
  }
}
