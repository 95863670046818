export class CRBConfigDto {
  rId: number
  name: string
  usernamePrefix: string
  domain: string
  oktaCustomDomain: string
  programCode: string
}

export class CRBConfigSettingDto {
  rId: number
  keyName: string
  keyValue: string
  CRBConfigRId: number
}

export class CRBConfigSettingUpsertDto {
  key: string
  value: string
}

export class CRBSettingKeys {
  // Colors
  static GlobalHeaderColor = 'globalHeaderColor'
  static CallToActionColor = 'callToActionColor'
  static TopNavigationBarColor = 'topNavigationBarColor'
  static ActiveNavFunctionColor = 'activeNavFunctionColor'
  static SecondaryFunctionColor = 'secondaryFunctionColor'
  static ModuleHeaderColor = 'moduleHeaderColor'
  static ModuleHeaderTextColor = 'moduleHeaderTextColor'
  static BottomNavigationColor = 'bottomNavigationColor'
  static SocialMediaIconColor = 'socialMediaIconColor'
  static DividerLineColor = 'dividerLineColor'
  static MainTextColor = 'mainTextColor'

  // Other Settings
  static BankSupportPhoneNumber = 'bankSupportPhoneNumber'
  static BankDisclosure = 'bankDisclosure'
  static IsTransferToBankEnabled = 'isTransferToBankEnabled'
  static IsOperatorSiteLinkEnabled  = 'isOperatorSiteLinkEnabled'
  static ExternalBankTransferInfoHeader = 'externalBankTransferInfoHeader'
  static ExternalBankTransferInfoBody = 'externalBankTransferInfoBody'
  static OperatorSiteLinkVerbiage = 'operatorSiteLinkVerbiage'
  static OperatorSiteLinkText = 'operatorSiteLinkText'
  static OperatorSiteLinkUrl = 'operatorSiteLinkUrl'

  // hubspot documents
  static WebsiteTermsOfUse = 'websiteTermsOfUse'
  static CRBPrivacyPolicy = 'cRBPrivacyPolicy'
  static ProgramPrivacyPolicy = 'programPrivacyPolicy'
  static ProgramFAQ = 'programFAQ'
  static ProgramDepositAgreement = 'programDepositAgreement'
  static PortalFAQ = 'portalFAQ'
}

export const BrandSettingKeys: string[] = [
  CRBSettingKeys.BankSupportPhoneNumber,
  CRBSettingKeys.BankDisclosure,
  CRBSettingKeys.IsTransferToBankEnabled,
  CRBSettingKeys.IsOperatorSiteLinkEnabled,
  CRBSettingKeys.ExternalBankTransferInfoHeader,
  CRBSettingKeys.ExternalBankTransferInfoBody,
  CRBSettingKeys.OperatorSiteLinkVerbiage,
  CRBSettingKeys.OperatorSiteLinkText,
  CRBSettingKeys.OperatorSiteLinkUrl,

  CRBSettingKeys.WebsiteTermsOfUse,
  CRBSettingKeys.CRBPrivacyPolicy,
  CRBSettingKeys.ProgramPrivacyPolicy,
  CRBSettingKeys.ProgramFAQ,
  CRBSettingKeys.ProgramDepositAgreement,
  CRBSettingKeys.PortalFAQ
]

export const BrandColorKeys: string[] = [
  CRBSettingKeys.GlobalHeaderColor,
  CRBSettingKeys.CallToActionColor,
  CRBSettingKeys.TopNavigationBarColor,
  CRBSettingKeys.ActiveNavFunctionColor,
  CRBSettingKeys.SecondaryFunctionColor,
  CRBSettingKeys.ModuleHeaderColor,
  CRBSettingKeys.ModuleHeaderTextColor,
  CRBSettingKeys.BottomNavigationColor,
  CRBSettingKeys.SocialMediaIconColor,
  CRBSettingKeys.DividerLineColor,
  CRBSettingKeys.MainTextColor
]

export const WebsiteGlobalFooter = [
  CRBSettingKeys.WebsiteTermsOfUse
]

export const PrivacyPolicy = [
  CRBSettingKeys.CRBPrivacyPolicy,
  CRBSettingKeys.ProgramPrivacyPolicy,
]

export const InfoActionMenuItems = [
  CRBSettingKeys.ProgramFAQ,
  CRBSettingKeys.ProgramDepositAgreement,
]

export const PreloginInfoItems = [
  CRBSettingKeys.PortalFAQ,
]
