/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable sonarjs/no-duplicate-string */
import { Component, OnInit } from '@angular/core'
import { BrandColors, BrandService } from '../services/ui/brandService.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { validColorValidator } from './valid-color.validator'
import { BrandFileKeys, FileDto, UniqueFileKeys } from '../services/api/models/file.model'
import { CacheService } from '../services/business/cache.service'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { BrandColorKeys, BrandSettingKeys, CRBConfigSettingDto } from '../services/api/models/configuration.model'
import { PreviewTokenType } from '../../security/models/token.model'
import { jwtDecode } from 'jwt-decode'
import { AuthWrapperService } from '../../security/services/auth'
import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { NotificationService } from '../services/ui/notification.service'

@Component({
  selector: 'app-style-configurator',
  templateUrl: './style-configurator.component.html',
  styleUrls: ['./style-configurator.component.scss'],
})
export class StyleConfiguratorComponent implements OnInit {

  faFileUpload = faCheckCircle

  brandColors: BrandColors
  brandFiles: FileDto[]
  brandSettings: CRBConfigSettingDto[]

  logoFileId: number
  logoFile: any
  logoFileInput: FormControl
  logoFilePreviewBase64: any = ''

  faviconFileId: number
  faviconFile: any
  faviconFileInput: FormControl
  faviconFilePreviewBase64: any = ''

  cardArtFileId: number
  cardArtFile: any
  cardArtFileInput: FormControl
  cardArtFilePreviewBase64: any = ''

  logoWhiteFileId: number
  logoWhiteFile: any
  logoWhiteFileInput: FormControl
  logoWhiteFilePreviewBase64: any = ''

  registerBackgroundFileId: number
  registerBackgroundFile: any
  registerBackgroundFileInput: FormControl
  registerBackgroundFilePreviewBase64: any = ''

  homeBackgroundFileId: number
  homeBackgroundFile: any
  homeBackgroundFileInput: FormControl
  homeBackgroundFilePreviewBase64: any = ''

  loginWatermarkFileId: number
  loginWatermarkFile: any
  loginWatermarkFileInput: FormControl
  loginWatermarkFilePreviewBase64: any = ''

  marketingBannerFile: any
  marketingBannerFileInput: FormControl
  marketingBannerImages: FileDto[]


  private programCode: string
  private crbConfigId: number
  private domain: string
  private type: PreviewTokenType = PreviewTokenType.View

  constructor(
    private brandService: BrandService,
    private cacheService: CacheService,
    private authWrapper: AuthWrapperService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.logoFileInput = new FormControl(this.logoFile)
    this.faviconFileInput = new FormControl(this.faviconFile)
    this.cardArtFileInput = new FormControl(this.cardArtFile)
    this.logoWhiteFileInput = new FormControl(this.logoWhiteFile)
    this.registerBackgroundFileInput = new FormControl(this.registerBackgroundFile)
    this.homeBackgroundFileInput = new FormControl(this.homeBackgroundFile)
    this.loginWatermarkFileInput = new FormControl(this.loginWatermarkFile)
    this.marketingBannerFileInput = new FormControl(this.marketingBannerFile)
  }

  public colorForm: FormGroup = new FormGroup(
    {
      bankSupportPhoneNumberInput: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      bankDisclosureInput: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      isOperatorSiteLinkEnabledInput: new FormControl(''),
      operatorSiteLinkVerbiageInput: new FormControl('', { updateOn: 'blur' }),
      operatorSiteLinkUrlInput: new FormControl('', { updateOn: 'blur' }),
      operatorSiteLinkTextInput: new FormControl('', { updateOn: 'blur' }),
      isTransferToBankEnabledInput: new FormControl(''),
      externalBankTransferInfoHeaderInput: new FormControl('', { updateOn: 'blur' }),
      externalBankTransferInfoBodyInput: new FormControl('', { updateOn: 'blur' }),

      programFAQInput: new FormControl('', { updateOn: 'blur' }),
      programDepositAgreementInput: new FormControl('', { updateOn: 'blur' }),
      programPrivacyPolicyInput: new FormControl('', { updateOn: 'blur' }),
      portalFAQInput: new FormControl('', { updateOn: 'blur' }),
      websiteTermsOfUseInput: new FormControl('', { updateOn: 'blur' }),
      cRBPrivacyPolicyInput: new FormControl('', { updateOn: 'blur' })
    },
    { updateOn: 'change' }
  )

  async ngOnInit(): Promise<void> {
    this.authWrapper.setAccessToken({
      accessToken: {
        accessToken: this.cacheService.getPreviewToken(),
        tokenType: 'Bearer'
      }
    })

    const token = jwtDecode(this.cacheService.getPreviewToken()) as any

    this.crbConfigId = token.CRBConfigRId
    this.programCode = token.ProgramCode
    this.domain = token.Domain
    this.type = token.Type
    this.cacheService.setPreviewModeType(this.type)
    this.brandColors = await this.brandService.getBrandColors(this.domain, true)

    for (const p in this.brandColors) {
      this.colorForm.addControl(`${p}InputCtrl`, new FormControl('', validColorValidator()))
      this.colorForm.addControl(`${p}PickerCtrl`, new FormControl(''))
    }

    this.brandFiles = await this.brandService.getBrandFiles(this.domain, true)

    if (this.brandFiles) {
      for (let i = 0; i < UniqueFileKeys.length; i++) {
        const brandFile = UniqueFileKeys[i]
        const apiFile = this.brandFiles.find(q => q.key == brandFile)

        if (apiFile) {
          this.loadURLToInputFiled(apiFile.url, `${this.camelize(apiFile.key)}FilePreviewBase64`)
        }
      }

      this.brandFiles.forEach(f => {
        this[`${this.camelize(f.key)}FileId`] = f.rId
      })

      this.marketingBannerImages = this.brandFiles.filter(f => f.key === BrandFileKeys.MarketingBanner)
    }

    this.brandSettings = this.cacheService.getBrandConfigSettings()

    if (this.brandSettings) {
      for (let i = 0; i < BrandSettingKeys.length; i++) {
        const brandSetting = BrandSettingKeys[i]
        const brandSettingConfig = this.brandSettings.find(q => q.keyName == brandSetting)
        if(brandSettingConfig) {
          this.colorForm.get(`${brandSetting}Input`).setValue(brandSettingConfig?.keyValue)
        }
      }
    }

    this.setInitialValues()
    this.initializeEvents()

    await this.brandService.setColors(this.brandColors)
    await this.brandService.setFiles(this.brandFiles)
    CacheService.brandConfigurationSettingsChanged.next(true)
  }

  public async removeFile(fileKey: string): Promise<void> {

    const alert = await this.alertController.create({
      mode: 'md',
      cssClass: 'confirmation-prompt',
      header: this.translateService.instant('commons.themeConfiguration.messages.removeFileHeader') as string,
      subHeader: this.translateService.instant('commons.themeConfiguration.messages.areYouSureRemoveFile') as string,
      buttons: [
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.cancel'
          ) as string,
        },
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.remove'
          ) as string,
          handler: async () => {
            const field = `${fileKey}FileId`
            await this.brandService.removeFile(this[field])
            this[field] = null

            await this.reloadConfiguration()
          }
        },
      ],
    })
    await alert.present()
  }

  public async removeMarketingFile(id: number): Promise<void> {

    const alert = await this.alertController.create({
      mode: 'md',
      cssClass: 'confirmation-prompt',
      header: this.translateService.instant('commons.themeConfiguration.messages.removeFileHeader') as string,
      subHeader: this.translateService.instant('commons.themeConfiguration.messages.areYouSureRemoveFile') as string,
      buttons: [
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.cancel'
          ) as string,
        },
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.remove'
          ) as string,
          handler: async () => {
            await this.brandService.removeFile(id)
            this.marketingBannerImages = this.marketingBannerImages.filter(f => f.rId !== id)

            await this.reloadConfiguration()
          }
        },
      ],
    })
    await alert.present()
  }

  private async reloadConfiguration() {
    this.brandColors = await this.brandService.getBrandColors(this.domain, true)
    this.brandFiles = await this.brandService.getBrandFiles(this.domain, true)
    this.brandSettings = this.cacheService.getBrandConfigSettings()

    await this.brandService.setColors(this.brandColors)
    await this.brandService.setFiles(this.brandFiles)
    this.cacheService.setBrandConfigSettings(this.brandSettings)

    this.cacheService.setConfiguredBrandingColors(this.brandColors)
    this.cacheService.setConfiguredBrandingFiles(this.brandFiles)
    this.cacheService.setConfiguredBrandConfigSettings(this.brandSettings)

    CacheService.brandConfigurationSettingsChanged.next(true)
  }

  private loadURLToInputFiled(url: string, fileInput: string): void {
    if (url) {
      this[fileInput] = url
    }
  }

  private initializeEvents() {

    this.logoFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('Logo')
    })

    this.logoWhiteFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('LogoWhite')
    })

    this.faviconFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('Favicon')
    })

    this.registerBackgroundFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('RegisterBackground')
    })

    this.homeBackgroundFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('HomeBackground')
    })

    this.loginWatermarkFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('LoginWatermark')
    })

    this.marketingBannerFileInput.valueChanges.subscribe(() => {
      try {
        const files = this['marketingBannerFileInput'].value

        files.forEach(f => {
          const reader = new FileReader()
          reader.readAsDataURL(f)
          reader.onload = async () => {
            const base64FileContent = reader.result as string
            const fileUploaded = {
              rId: null,
              content: base64FileContent.split(',').pop(),
              fileKey: BrandFileKeys.MarketingBanner,
              fileName: f.name
            }
            const rId = await this.brandService.updateBrandFile(fileUploaded)
            fileUploaded.rId = rId
            this.marketingBannerImages.push({
              rId: 0,
              key: fileUploaded.fileKey,
              name: fileUploaded.fileName,
              url: base64FileContent
            })
          }
        })
      }
      catch (err) {
        void this.notificationService.showToasterErrorMessage((this.translateService.instant('commons.themeConfiguration.files.error') as string) + err?.error?.error as string)
        void this.reloadConfiguration()
      }
    })

    this.cardArtFileInput.valueChanges.subscribe(() => {
      this.setFilePreview('CardArt')
    })

    //socialMediaIconColor
    this.colorForm.controls['socialMediaIconColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['socialMediaIconColorPickerCtrl'].valid) {
        this.colorForm.controls['socialMediaIconColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['socialMediaIconColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['socialMediaIconColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //bottomNavigationColor
    this.colorForm.controls['bottomNavigationColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['bottomNavigationColorPickerCtrl'].valid) {
        this.colorForm.controls['bottomNavigationColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['bottomNavigationColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['bottomNavigationColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //globalHeaderColor
    this.colorForm.controls['globalHeaderColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['globalHeaderColorPickerCtrl'].valid) {
        this.colorForm.controls['globalHeaderColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['globalHeaderColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['globalHeaderColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //callToActionColor
    this.colorForm.controls['callToActionColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['callToActionColorPickerCtrl'].valid) {
        this.colorForm.controls['callToActionColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['callToActionColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['callToActionColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //topNavigationBarColor
    this.colorForm.controls['topNavigationBarColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['topNavigationBarColorPickerCtrl'].valid) {
        this.colorForm.controls['topNavigationBarColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['topNavigationBarColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['topNavigationBarColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //activeNavFunctionColor
    this.colorForm.controls['activeNavFunctionColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['activeNavFunctionColorPickerCtrl'].valid) {
        this.colorForm.controls['activeNavFunctionColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['activeNavFunctionColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['activeNavFunctionColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //secondaryFunctionColor
    this.colorForm.controls['secondaryFunctionColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['secondaryFunctionColorPickerCtrl'].valid) {
        this.colorForm.controls['secondaryFunctionColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['secondaryFunctionColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['secondaryFunctionColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //moduleHeaderColor
    this.colorForm.controls['moduleHeaderColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['moduleHeaderColorPickerCtrl'].valid) {
        this.colorForm.controls['moduleHeaderColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['moduleHeaderColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['moduleHeaderColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //moduleHeaderTextColor
    this.colorForm.controls['moduleHeaderTextColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['moduleHeaderTextColorPickerCtrl'].valid) {
        this.colorForm.controls['moduleHeaderTextColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['moduleHeaderTextColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['moduleHeaderTextColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //dividerLineColor
    this.colorForm.controls['dividerLineColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['dividerLineColorPickerCtrl'].valid) {
        this.colorForm.controls['dividerLineColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['dividerLineColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['dividerLineColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    //mainTextColor
    this.colorForm.controls['mainTextColorInputCtrl'].valueChanges.subscribe((color) => {
      if (this.colorForm.controls['mainTextColorPickerCtrl'].valid) {
        this.colorForm.controls['mainTextColorPickerCtrl'].setValue(color, {
          emitEvent: false,
        })
        void this.updateColors()
      }
    })

    this.colorForm.controls['mainTextColorPickerCtrl'].valueChanges.subscribe((color) => {
      this.colorForm.controls['mainTextColorInputCtrl'].setValue(color, {
        emitEvent: false,
      })
      void this.updateColors()
    })

    this.colorForm.controls['isOperatorSiteLinkEnabledInput'].valueChanges.subscribe((value) => {
      if (value) {
        this.colorForm.controls['operatorSiteLinkVerbiageInput'].enable()
        this.colorForm.controls['operatorSiteLinkUrlInput'].enable()
        this.colorForm.controls['operatorSiteLinkTextInput'].enable()
      }
      else {
        this.colorForm.controls['operatorSiteLinkVerbiageInput'].setValue('', {
          emitEvent: false,
        })
        this.colorForm.controls['operatorSiteLinkUrlInput'].setValue('', {
          emitEvent: false,
        })
        this.colorForm.controls['operatorSiteLinkTextInput'].setValue('', {
          emitEvent: false,
        })

        this.colorForm.controls['operatorSiteLinkVerbiageInput'].disable()
        this.colorForm.controls['operatorSiteLinkUrlInput'].disable()
        this.colorForm.controls['operatorSiteLinkTextInput'].disable()
      }

      void this.updateSettings()
    })

    this.colorForm.controls['isTransferToBankEnabledInput'].valueChanges.subscribe((value) => {
      if (value) {
        this.colorForm.controls['externalBankTransferInfoHeaderInput'].enable()
        this.colorForm.controls['externalBankTransferInfoBodyInput'].enable()
      }
      else {
        this.colorForm.controls['externalBankTransferInfoHeaderInput'].setValue('', {
          emitEvent: false,
        })
        this.colorForm.controls['externalBankTransferInfoBodyInput'].setValue('', {
          emitEvent: false,
        })

        this.colorForm.controls['externalBankTransferInfoHeaderInput'].disable()
        this.colorForm.controls['externalBankTransferInfoBodyInput'].disable()
      }

      void this.updateSettings()
    })

    this.colorForm.controls['bankSupportPhoneNumberInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['bankDisclosureInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['operatorSiteLinkVerbiageInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['operatorSiteLinkUrlInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['operatorSiteLinkTextInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['externalBankTransferInfoHeaderInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['externalBankTransferInfoBodyInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['programFAQInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['programDepositAgreementInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['programPrivacyPolicyInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['portalFAQInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['websiteTermsOfUseInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })

    this.colorForm.controls['cRBPrivacyPolicyInput'].valueChanges.subscribe(() => {
      void this.updateSettings()
    })
  }

  private async updateFile(fileKey: string) {
    try {
      const rId = await this.brandService.updateBrandFile({
        rId: this[`${this.camelize(fileKey)}FileId`],
        fileKey: BrandFileKeys[fileKey],
        fileName: this[`${this.camelize(fileKey)}FileInput`]?.value?.name,
        content: this[`${this.camelize(fileKey)}FilePreviewBase64`].split(',').pop()
      })

      this[`${this.camelize(fileKey)}FileId`] = rId
      await this.reloadConfiguration()
    }
    catch (err) {
      await this.notificationService.showToasterErrorMessage((this.translateService.instant('commons.themeConfiguration.files.error') as string) + err?.error?.error as string)
      this[`${this.camelize(fileKey)}FilePreviewBase64`] = ''
      await this.reloadConfiguration()
    }
  }

  private setFilePreview(fileKey: string) {
    const reader = new FileReader()
    reader.readAsDataURL(this[`${this.camelize(fileKey)}FileInput`].value)
    reader.onload = () => {
      this[`${this.camelize(fileKey)}FilePreviewBase64`] = reader.result
      void this.updateFile(fileKey)
    }
  }

  private setInitialValues() {
    for (const p in this.brandColors) {
      this.colorForm.get(`${p}InputCtrl`).setValue(this.brandColors[p])
      this.colorForm.get(`${p}PickerCtrl`).setValue(this.brandColors[p])
    }
  }

  private async updateColors() {
    const colorsDictionary = {}
    BrandColorKeys.forEach(c => {
      colorsDictionary[c] = this.colorForm.get(`${c}InputCtrl`).value
    })

    await this.brandService.updateBrandConfigSettings(colorsDictionary)
    await this.reloadConfiguration()
  }

  private async updateSettings() {
    const configSettings = {}
    BrandSettingKeys.forEach(c => {
      configSettings[`${c}`] = this.colorForm.get(`${c}Input`).value + ''
    })

    await this.brandService.updateBrandConfigSettings(configSettings)
    await this.reloadConfiguration()
  }

  private camelize(str): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '').replace('cRB', 'crb')
  }

  public async publishConfiguration(): Promise<void> {
    const alert = await this.alertController.create({
      mode: 'md',
      cssClass: 'confirmation-prompt',
      header: this.translateService.instant('commons.themeConfiguration.messages.publishConfigurationHeader') as string,
      subHeader: this.translateService.instant('commons.themeConfiguration.messages.areYouSurePublishConfiguration') as string,
      buttons: [
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.cancel'
          ) as string,
        },
        {
          text: this.translateService.instant(
            'commons.themeConfiguration.messages.publish'
          ) as string,
          handler: async () => {
            try {
              const success = await this.brandService.publishBrandConfiguration(this.crbConfigId, this.programCode)
              if (success) {
                void this.notificationService.showToasterMessage({
                  message: this.translateService.instant('commons.themeConfiguration.messages.publishSuccessMessage') as string,
                })
              }
              else {
                void this.notificationService.showToasterErrorMessage(
                  this.translateService.instant('commons.themeConfiguration.messages.publishErrorMessage') as string,
                )
              }
            }
            catch {
              void this.notificationService.showUnhandledToasterErrorMessage()
            }
          }
        },
      ],
    })
    await alert.present()
  }
}
