export class FileDto {
  rId: number
  name: string
  key: string
  url: string
}

export class UpsertFileDto {
  rId: number
  fileName: string
  fileKey: string
  content: string
}

export class BrandFileKeys {
  //unique key
  static Favicon = 'Favicon'
  static Logo = 'Logo'
  static LogoWhite = 'LogoWhite'
  static CardArt = 'CardArt'
  static RegisterBackground = 'RegisterBackground'
  static HomeBackground = 'HomeBackground'
  static LoginWatermark = 'LoginWatermark'

  //multiple keys
  static MarketingBanner = 'MarketingBanner'
}

export const UniqueFileKeys = [
  BrandFileKeys.Favicon,
  BrandFileKeys.Logo,
  BrandFileKeys.LogoWhite,
  BrandFileKeys.CardArt,
  BrandFileKeys.RegisterBackground,
  BrandFileKeys.HomeBackground,
  BrandFileKeys.LoginWatermark
]