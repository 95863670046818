/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { ApiServiceBase, IApiConfig } from '@sightline/models-misc'
import { HTTPMethod } from 'http-method-enum'
import { CompleteRegistrationDto, RegisterCustomer, UserInfoDto, TokenResponseDto } from '../../models'
import { TokenType } from '../../models/token.model'
import {
  ActivateMfaRequestDto,
  AddUserToOktaGroupRequestDto,
  EnrollMfaRequestDto,
  FactorEnrolledResponseDto,
  UserFactorDto,
  UserPreferenceDto,
  UserPreferenceKey,
  VerifyMfaCodeDto
} from '../../models/mfa.model'

export const API_CONFIG = new InjectionToken<IApiConfig>('ApiConfig')

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private serviceBase: ApiServiceBase, @Inject(API_CONFIG) @Optional() private config?: IApiConfig) { }

  public async validateToken(tokenValue: string, tokenType: TokenType, previewMode: boolean): Promise<TokenResponseDto> {
    if (previewMode) {
      return this.serviceBase
        .callProtectedEndpoint<TokenResponseDto>(
          HTTPMethod.GET,
          `users/validate-token/${tokenValue}/${tokenType}`,
          this.config
        )
        .toPromise()
    } else {
      return this.serviceBase
        .callUnprotectedEndpoint<TokenResponseDto>(
          HTTPMethod.GET,
          `users/validate-token/${tokenValue}/${tokenType}`,
          this.config
        )
        .toPromise()
    }
  }

  public async postRegister(registerCustomer: RegisterCustomer): Promise<number> {
    return await this.serviceBase
      .callProtectedEndpoint<number>(HTTPMethod.POST, 'users/register', this.config, registerCustomer)
      .toPromise()
  }

  public async postCompleteRegistration(completeRegistration: CompleteRegistrationDto): Promise<boolean> {
    return this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/complete-registration', this.config, completeRegistration)
      .toPromise()
  }

  public async forgotPassword(username: string, previewMode: boolean): Promise<boolean> {
    if (previewMode) {
      return this.serviceBase
        .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/forgot-password', this.config, {
          username,
        })
        .toPromise()
    } else {
      return this.serviceBase
        .callUnprotectedEndpoint<boolean>(HTTPMethod.POST, 'users/forgot-password', this.config, {
          username,
        })
        .toPromise()
    }
  }

  public async forgotUsername(email: string, previewMode: boolean): Promise<boolean> {
    if (previewMode) {
      return this.serviceBase
        .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/forgot-username', this.config, {
          email,
        })
        .toPromise()
    } else {
      return this.serviceBase
        .callUnprotectedEndpoint<boolean>(HTTPMethod.POST, 'users/forgot-username', this.config, {
          email,
        })
        .toPromise()
    }
  }

  public async isUsernameAvailable(username: string, registrationToken: string, previewMode: boolean): Promise<boolean> {
    if (previewMode) {
      return this.serviceBase
        .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/is-username-available', this.config, {
          username,
          registrationToken,
        })
        .toPromise()
    } else {
      return this.serviceBase
        .callUnprotectedEndpoint<boolean>(HTTPMethod.POST, 'users/is-username-available', this.config, {
          username,
          registrationToken,
        })
        .toPromise()
    }
  }

  public async resetPassword(forgotPasswordToken: string, newPassword: string, previewMode: boolean): Promise<boolean> {
    if (previewMode) {
      return this.serviceBase
        .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/reset-password', this.config, {
          forgotPasswordToken: forgotPasswordToken,
          newPassword: newPassword,
        })
        .toPromise()
    } else {
      return this.serviceBase
        .callUnprotectedEndpoint<boolean>(HTTPMethod.POST, 'users/reset-password', this.config, {
          forgotPasswordToken: forgotPasswordToken,
          newPassword: newPassword,
        })
        .toPromise()
    }
  }

  public async changePassword(newPassword: string): Promise<boolean> {
    return this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/change-password', this.config, {
        newPassword: newPassword
      })
      .toPromise()
  }

  public getUserInfo(): Promise<UserInfoDto> {
    return this.serviceBase
      .callProtectedEndpoint<UserInfoDto>(HTTPMethod.GET, 'users/info', this.config)
      .toPromise()
  }

  //mfa methods
  public postAddUserToOktaGroup(addUserToOktaGroupRequestDto: AddUserToOktaGroupRequestDto): Promise<void> {
    return this.serviceBase
      .callProtectedEndpoint<void>(HTTPMethod.POST, 'users/add-okta-group', this.config, addUserToOktaGroupRequestDto)
      .toPromise()
  }

  public postMfaEnroll(enrollRequestDto: EnrollMfaRequestDto): Promise<FactorEnrolledResponseDto> {
    return this.serviceBase
      .callProtectedEndpoint<FactorEnrolledResponseDto>(HTTPMethod.POST, 'users/mfa-enroll', this.config, enrollRequestDto)
      .toPromise()
  }

  public postMfaActivate(activateRequestDto: ActivateMfaRequestDto): Promise<boolean> {
    return this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/mfa-activate', this.config, activateRequestDto)
      .toPromise()
  }

  public getMfaFactors(): Promise<Array<UserFactorDto>> {
    return this.serviceBase
      .callProtectedEndpoint<Array<UserFactorDto>>(HTTPMethod.GET, 'users/mfa-factors', this.config)
      .toPromise()
  }
  //end mfa methods

  public getUserPreferences(keys: Array<UserPreferenceKey>): Promise<Array<UserPreferenceDto>> {
    let query = ''
    for (let i = 0; i < keys.length; i++) {
      query += `keys=${keys[i]}`
    }

    return this.serviceBase
      .callProtectedEndpoint<Array<UserPreferenceDto>>(HTTPMethod.GET, `users/user-preferences?${query}`, this.config)
      .toPromise()
  }

  public postUserPreferences(userPreferences: Array<UserPreferenceDto>): Promise<void> {
    return this.serviceBase
      .callProtectedEndpoint<void>(HTTPMethod.POST, 'users/user-preferences', this.config, userPreferences)
      .toPromise()
  }

  public getUserMfaPreference = (username: string, previewMode: boolean): Promise<UserPreferenceDto> => {
    if (previewMode) {
      return this.serviceBase.callProtectedEndpoint<UserPreferenceDto>(HTTPMethod.GET, `users/mfa-preference?username=${encodeURIComponent(username)}`, this.config)
        .toPromise()
    } else {
      return this.serviceBase.callUnprotectedEndpoint<UserPreferenceDto>(HTTPMethod.GET, `users/mfa-preference?username=${encodeURIComponent(username)}`, this.config)
        .toPromise()
    }
  }

  public postSendMfaCode(): Promise<boolean> {
    return this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/mfa-code', this.config)
      .toPromise()
  }

  public postVerifyMfaCode(verifyMfaCodeDto: VerifyMfaCodeDto): Promise<boolean> {
    return this.serviceBase
      .callProtectedEndpoint<boolean>(HTTPMethod.POST, 'users/mfa-code/verify', this.config, verifyMfaCodeDto)
      .toPromise()
  }
}
